import Head from "next/head";
import NavBar from "./NavBar";
import Footer from "./Footer";

export default function Layout({ children, title }) {
  return (
    <div>
      <Head>
        <title>{title || "Reactify"}</title>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
        ></script>
      </Head>
      <div className="min-h-screen bg-gray-100">
        <NavBar />
        {children}
      </div>
      <Footer />
    </div>
  );
}
