import React from "react";
import classnames from "classnames";
import Link from "next/link";
import Twitter from "src/images/inline-svg/twitter.svg";
import Github from "src/images/inline-svg/github.svg";
import Linkedin from "src/images/inline-svg/linkedin.svg";
import YouTube from "src/images/inline-svg/youtube.svg";
import Menu from "src/images/inline-svg/menu.svg";
import Close from "src/images/inline-svg/x.svg";
import Calender from "src/images/inline-svg/calendar.svg";
import Clock from "src/images/inline-svg/clock.svg";
import User from "src/images/inline-svg/user.svg";

function createIcon(SvgIconComponent) {
  return function Icon({ href, className }) {
    const iconClassName = "";
    return (
      <Link href={href}>
        <a className="cursor-pointer">
          <SvgIconComponent className={classnames(iconClassName, className)} />
        </a>
      </Link>
    );
  };
}

export const TwitterIcon = createIcon(Twitter);
export const YouTubeIcon = createIcon(YouTube);
export const GithubIcon = createIcon(Github);
export const LinkedinIcon = createIcon(Linkedin);
export const MenuIcon = Menu;
export const CloseIcon = Close;
export const CalenderIcon = Calender;
export const ClockIcon = Clock;
export const UserIcon = User;
