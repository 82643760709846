import React from "react";
import { useState } from "react";
import Logo from "./Logo";
import Container from "./Container";
import Link from "next/link";
import MobileMenu from "./MobileMenu";
import { PrimaryButton, SecondaryButton } from "./Buttons";
import { Popover, ArrowContainer } from "react-tiny-popover";
import { UserIcon } from "src/components/ui/Icons";
import useUser from "src/hooks/useUser";

function PopoverContent({ position, childRect, popoverRect }) {
  return (
    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
      position={position}
      childRect={childRect}
      popoverRect={popoverRect}
      arrowColor={"#F3F4F6"}
      arrowSize={10}
      // arrowStyle={{ opacity: 0.7 }}
      className="popover-arrow-container"
      arrowClassName="popover-arrow"
    >
      <div
        className="px-8 py-2 bg-gray-100 text-xl"
        style={{
          boxShadow: "0 4px 20px rgba(0,0,0,0.12)",
        }}
      >
        <Link href="/logout">Logout</Link>
      </div>
    </ArrowContainer>
  );
}

function NavBarUserSection() {
  const { loading, user } = useUser();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  if (loading) {
    return null;
  }

  if (!user) {
    return (
      <ul className="hidden md:flex items-center">
        <li>
          <PrimaryButton href="/login" md={true}>
            Login
          </PrimaryButton>
        </li>
      </ul>
    );
  }

  return (
    <div className="hidden md:flex items-center">
      <Popover
        isOpen={isPopoverOpen}
        positions={["bottom", "left"]} // preferred positions by priority
        onClickOutside={() => setIsPopoverOpen(false)}
        content={(props) => <PopoverContent {...props} />}
      >
        <div
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          className="text-xl"
        >
          <UserIcon />
        </div>
      </Popover>
    </div>
  );
}

export default function NavBar() {
  const navLinkClass = "mx-4 text-xl hover:underline";
  return (
    <Container
      backgroundClassName="bg-white border-b border-gray-200"
      className="py-6 flex justify-between items-center"
    >
      <div className="flex items-center">
        <Logo />
        <ul className="hidden md:flex ml-16">
          <li>
            <Link href="/events">
              <a className={navLinkClass}>Events</a>
            </Link>
          </li>
        </ul>
      </div>
      {/* <NavBarUserSection /> */}
      <MobileMenu />
    </Container>
  );
}
