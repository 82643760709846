import useSWR from "swr";

export default function useUser() {
  const { data, error, mutate } = useSWR("/api/user", {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });
  return {
    loading: !data && !error,
    user: data,
    mutate,
  };
}
