import React from "react";
import Link from "next/link";
import Container from "./Container";
import Logo from "./Logo";
import { TwitterIcon, YouTubeIcon } from "./Icons";

function SocialLinks() {
  return (
    <div className="flex">
      <div className="mr-2">
        <TwitterIcon href="https://twitter.com/reactify_in" />
      </div>
      <div>
        <YouTubeIcon href="https://www.youtube.com/reactify_in" />
      </div>
    </div>
  );
}

function Divider() {
  return <div className="px-2 text-lg font-semibold">|</div>;
}

function FooterLinks() {
  const linkClassName = "cursor-pointer text-lg font-bold hover:underline";
  return (
    <div className="flex my-1">
      <Link href="/events">
        <a className={linkClassName}>Events</a>
      </Link>
      {/* <Divider />
      <Link href="/contact-us">
        <a className={linkClassName}>Contact Us</a>
      </Link> */}
    </div>
  );
}

function Copyright() {
  return (
    <div className="text-base mx-10 md:mx-0 text-center">
      &copy; 2021 Reactify. All rights reserved
    </div>
  );
}

export default function Footer() {
  return (
    <Container
      className="relative py-10"
      backgroundClassName="border-t border-gray-200"
    >
      <div className="flex flex-col md:flex-row md:justify-between">
        <div className="hidden md:block">
          <Logo />
        </div>
        <div className="flex flex-col items-center md:items-end">
          <SocialLinks />
          <FooterLinks />
          <div className="block md:hidden py-5">
            <Logo />
          </div>
          <Copyright />
        </div>
      </div>
    </Container>
  );
}
