import React, { useContext } from "react";
import Modal from "react-modal";
import Link from "next/link";
import Logo from "./Logo";
import { CloseIcon, MenuIcon } from "./Icons";
import useUser from "src/hooks/useUser";

const navLinkClass = "text-xl font-semibold hover:underline  leading-loose";

const customStyles = {
  content: {
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
  },
};

Modal.setAppElement("body");

function LoginSection() {
  const { loading, user } = useUser();
  if (!loading && !user) {
    return (
      <li>
        <Link href="/login">
          <a className={navLinkClass}>Login</a>
        </Link>
      </li>
    );
  }
  return null;
}

function LogoutSection() {
  const { loading, user } = useUser();

  if (!loading && user) {
    return (
      <li className="mt-10">
        <div>Logged in</div>
        <Link href="/logout">
          <a className={navLinkClass}>Logout</a>
        </Link>
      </li>
    );
  }
  return null;
}

export default function MobileMenu({}) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  function closeModal() {
    setIsModalOpen(false);
  }
  function openModal() {
    setIsModalOpen(true);
  }
  return (
    <>
      <MenuIcon
        className="cursor-pointer md:hidden"
        onClick={openModal}
        alt="menu icon"
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        className="bg-white z-50"
      >
        <div
          className="flex flex-col items-center h-screen bg-white z-50"
          onClick={closeModal}
        >
          <CloseIcon
            onClick={closeModal}
            className="w-8 self-end m-5 cursor-pointer"
          />
          <ul className="mb-32 text-center">
            {/* {isModalOpen && <LoginSection />} */}
            <li>
              <Link href="events">
                <a className={navLinkClass}>Events</a>
              </Link>
            </li>
            {/* {isModalOpen && <LogoutSection />} */}
          </ul>
          <Logo />
        </div>
      </Modal>
    </>
  );
}
