import classNames from "classnames";
import Link from "next/link";

export function PrimaryButton({
  children,
  lg,
  sm,
  md,
  href,
  type,
  disabled,
  onClick,
}) {
  const className = classNames(
    "bg-black border-2 border-black text-white rounded hover:opacity-70",
    "cursor-pointer outline-none focus:outline-none",
    {
      "text-xl md:text-2xl px-8 md:px-10 py-4": lg,
      "text-xl px-6 py-3": md,
      "text-sm px-4 py-1": sm,
      "cursor-not-allowed opacity-70": disabled,
    }
  );
  if (href) {
    return (
      <Link href={href}>
        <a className={className}>{children}</a>
      </Link>
    );
  }
  return (
    <button
      className={className}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export function SecondaryButton({
  children,
  lg,
  sm,
  md,
  href,
  type,
  disabled,
  onClick,
}) {
  const className = classNames(
    "rounded border-2 border-gray-400 hover:border-black",
    "cursor-pointer outline-none focus:outline-none",
    {
      "text-xl md:text-2xl px-8 md:px-10 py-4": lg,
      "text-xl px-6 py-3": md,
      "text-sm px-4 py-1": sm,
    }
  );
  if (href) {
    return (
      <Link href={href}>
        <a className={className}>{children}</a>
      </Link>
    );
  }
  return (
    <button
      className={className}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
