import Link from "next/link";

export default function Logo() {
  return (
    <Link href="/">
      <a>
        <h1 className="font-bold text-3xl">Reactify</h1>
      </a>
    </Link>
  );
}
