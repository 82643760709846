import classnames from "classnames";

export default function Container({
  children,
  backgroundClassName,
  className,
}) {
  return (
    <div className={backgroundClassName}>
      <div className={classnames("max-w-screen-xl mx-4 md:mx-auto", className)}>
        {children}
      </div>
    </div>
  );
}
